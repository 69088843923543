@import url(./components/LandingPage/Channel/MANAGEX/fonts.css);
:root {
  --grayE: #e9ecf2;
}
body,
html {
  font-family: "Abadi";
}
* {
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.tabsMainCon {
  position: relative;
}

.tmcHead {
  display: flex;
  position: absolute;
  top: 2rem;
  z-index: 1;
  width: 100%;
  justify-content: center;
  border-bottom: 2px solid #f2f2f2;
}

.tmcHead button {
  width: auto;
  cursor: pointer;
  padding: 0.5rem 3.5rem;
  background-color: transparent;
  margin: 0 2rem;
}

.tmcHead button.active:after {
  content: "";
  height: 9px;
  width: 100%;
  background-color: #2f52e8;
  position: absolute;
  bottom: -5px;
  border-radius: 5px;
}

.tmcHead p {
  display: flex;
  white-space: nowrap;
  font-size: 1.7rem;
}

.tmcHead img {
  width: 55px;
  margin-right: 1rem;
}

.tmcBody {
  padding-top: 9rem;
  background-image: url(./assert/images/signupbg.webp);
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  padding-bottom: 3rem;
}

.tmctabTtl {
  text-align: center;
}

.tmctabTtl h3 {
  font-size: 2.3rem;
  margin-bottom: 1rem;
  color: #ffffff;
  font-weight: 600;
  display: flex;
}

.tmctabTtl h3 img {
  height: 45px;
  margin-right: 1.5rem;
}

.tmctabTtl p {
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
}

.indOurMis {
  background-image: url("./assert/images/partnerbg.png");
  background-size: cover;
  background-position: top right;
  position: relative;
}
.iomInner {
  width: 80%;
  margin-left: auto;
  margin-right: 5%;
  padding-top: 20%;
  padding-bottom: 7rem;
}

/*Dashboard*/
.panelGray {
  background-color: var(--grayE);
  padding: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-top: 2rem;
  border-radius: 4px;
}
.panelGray .pgTtl {
  font-weight: 500;
  font-size: 12px;
  padding-bottom: 10px;
  /* font-family: Poppins; */
  margin: 0;
}
/* .announcementTable tbody tr {
  background-color: #ffffff;
  border-bottom: 0;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  border-radius: 9px;
  margin-bottom: 7px;
}
.announcementTable tbody tr:hover {
  background-color: #ffffff;
}
.announcementTable tbody tr > td:first-child {
  height: auto;
} */
.announcementTable th button {
  font-weight: 600;
}
.announcementTable th:last-child button {
  justify-content: flex-end;
}
.announcementTable tr > td:last-child {
  text-align: right;
}
.announcementTable tr td:first-child {
  border-left: 4px solid transparent;
  padding-left: 7px;
}
.announcementTable tr td.primary {
  border-left-color: #479ef5;
}
.announcementTable tr td.warning {
  border-left-color: #fde300;
}
.announcementTable tr td.danger {
  border-left-color: #d13438;
}
.announcementTable .buttonAnnouncement {
  padding: 5px;
  line-height: 1;
  min-width: unset;
}
.announcementTable tr {
  border-bottom: 1px solid #cccccc;
}
.announcementTable .buttonAnnouncement svg {
  font-size: 1.1rem;
}
.panelGray .pgPanel i {
  font-size: 1.2rem;
}
.panelGray .pgInfo {
}
/* Announcement Modal */
.announcementModal h4 {
  font-weight: 600;
  margin-top: 0;
}
/* Dialog box */
div[role="dialog"] {
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
}
.fui-DialogBody .fui-DialogTitle {
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 7px;
}
@media (max-width: 959px) {
  .tmcHead button {
    padding: 0.5rem 1.5rem;
  }

  .tmcHead p {
    font-size: 1.3rem;
  }

  .tmcHead img {
    width: 35px;
  }

  .tmctabTtl h3 {
    font-size: 1.7rem;
  }

  .tmctabTtl h3 img {
    height: 31px;
  }
  .tmcBody {
    padding-top: 8rem;
  }
  .indOurMis {
    background-position: center right;
  }
  .iomInner {
    width: 100%;
    padding: 7rem 2rem 3rem;
  }
}

@media (max-width: 500px) {
  .tmcHead img {
    display: none;
  }
  .tmcHead p {
    font-size: 1.1rem;
  }
  .tmcHead button.active:after {
    height: 6px;
    bottom: -4px;
  }
}

.tick-list {
  list-style: none; /* Remove default list styles */
  padding: 0; /* Remove default padding */
}

.tick-list li::before {
  content: "\2713"; /* Unicode character for a checkmark symbol */
  margin-right: 8px; /* Add some spacing between the checkmark and the text */
}

.tick-list li {
  margin: 24px 0px;
}
