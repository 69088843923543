@font-face {
  font-family: "Abadi";
  src: url(./assets/fonts/Abadi/AbadiMT.ttf);
}
@font-face {
  font-family: "AbadiB";
  src: url(./assets/fonts/Abadi/AbadiMT-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: "AbadiL";
  src: url(./assets/fonts/Abadi/AbadiMT-Light.ttf);
}

body {
  font-family: "Abadi", Tahoma, Geneva, Verdana, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "AbadiB", Tahoma, Geneva, Verdana, sans-serif;
}
b,
strong {
  font-family: "AbadiB", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
}
